<template>
    <section id="dashboard-ecommerce">
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row class="match-height">
                <b-col xl="6" md="6">
                    <b-card no-body>
                        <b-row class="m-1">
                            <b-col md="12" xl="5" sm="12">
                                <b-form-group label="Conexión" label-for="idEmpresa" label-class="font-weight-bold">
                                    <v-select 
                                        disabled
                                        id="idEmpresa"                                        
                                        class="select-size-sm"
                                        v-model="filter.idEmpresa"
                                        :options="connections"
                                        :reduce="option => option.idEmpresa" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectConnection" />
                                </b-form-group>
                            </b-col>
                            <b-col md="12" xl="4" sm="12">
                                <b-form-group label="Tipo" label-for="idTipo" label-class="font-weight-bold">
                                    <v-select
                                        id="idTipo"                                        
                                        class="select-size-sm"
                                        v-model="filter.idTipo"
                                        :options="[ { id: 1, nombre: 'Grupos' }, { id: 2, nombre: 'Lineas' } ]"
                                        :reduce="option => option.id" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false" />
                                </b-form-group>
                            </b-col>
                            <b-col md="12" xl="3" sm="12">
                                <b-button size="sm" variant="primary" class="mt-2">
                                    <feather-icon icon="SearchIcon"/>
                                    Buscar
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-overlay>
    </section>
</template>

<script>
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab,
} from 'bootstrap-vue'
import store from '@/store'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from "vue-select";
import { RecycleScroller } from 'vue-virtual-scroller';
import * as XLSX from 'xlsx';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import { computed, ref } from '@vue/composition-api';

export default {
    name: "RelationLines",
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BAvatar,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        BTabs,
        BTab,

        //
        BCardActions,
        vSelect,
        RecycleScroller,
        FeatherIcon,
    },
    setup() {

        const loading = ref(false)
        const filter = ref({
            idEmpresa: 1,
            tipo: null,
        })

        const connections = computed(() => {
            return store.getters['auth/getConnections'].filter(c => c.idEmpresa === 1)
        })

        const selectConnection = (value) => {
            console.log(value)
        }

        return {
            loading,
            filter,

            //computed
            connections,

            //methods
            selectConnection,
        }
        
    },
}
</script>